<template>
    <ResetPassword/>
</template>

<script>
import ResetPassword from '../../components/auth/ResetPassword'
export default {

    components: {
        ResetPassword
    }
}
</script>

<style>

</style>
