<template>
<div>
    <div class="login-div" style="background-size:contain">

        <div class="row">
            <div class="col-lg-8 col-12"></div>
            <div class="col-lg-4 col-12 login-container text-center"> 
                <img loading="lazy" src="../../assets/images/logo.png" height="70" class="mt-60"  @click="sendToMarket">
                <div class="form-div">
                    <form action class="form">
                        <h4 class="mt-2 mb-4 head-font">{{tran.passwordReset}}</h4>
                        <div class="form-group">
                            <label class="form-label" for="token" @click.prevent="focusOnInput($event)">{{tran.token}}</label>
                            <input id="token" v-model="formdata.token" class="form-input"
                  type="number"
                  autocomplete="off" @keyup="checkEnterButton">
                        </div>
                            <div class="form-group">
                                <label class="form-label" for="first" @click.prevent="focusOnInput($event)">{{tran.newPassword}}</label>
                                <div class="eye-div">
                                    <input
                    v-model="formdata.password"
                    class="form-input"
                    :type="hideInput"
                    autocomplete="off"
                  @keyup="checkEnterButton">
                                    <i class="fa fa-eye-slash" @click="showInputText" v-if="showEye"></i>
                                    <i class="fa fa-eye" @click="hideInputText" v-if="hideEye"></i>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-label" for="last">{{tran.retypePassword}}</label>
                                <input class="form-input" :type="hideInput" autocomplete="off" @keyup="checkEnterButton">
              </div>

                                <div class="form-group">
                                    <button
                  type="button"
                  class="submit-btn mt-2"
                  :class="{'fade-elem': loader}"
                  @click.prevent="updatePassword">
                  {{ loader ? '' : tran.reset }}
                  <span class="loader mt-1" v-if="loader"></span>
                </button>
                                </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import trans from "../../../translations";
import {
    mapState
} from "vuex";

import {userNotification} from "../dashboard/mixins/notifyUser.js";
export default {

     mixins:[userNotification],
    data() {
        return {
            tran: {},
            hideEye: false,
            showEye: true,
            hideInput: "password"
        };
    },
    computed: {
        ...mapState({
            formdata: state => state.auth.updatePasswordData,
            loader: state => state.auth.loader
        })
    },
    methods: {
          sendToMarket(){
            this.$router.push('/market')
        },

        updatePassword() {
            this.$store.dispatch("auth/updatePassword");
        },
        showInputText() {
            this.hideInput = "text";
            this.showEye = false;
            this.hideEye = true;
        },

        hideInputText() {
            this.hideInput = "password";
            this.showEye = true;
            this.hideEye = false;
        },

        focusOnInput(e) {

            e.target.parentNode.querySelector('.form-input').focus();
        },

        checkEnterButton() {
            if (event.keyCode === 13) {
                this.updatePassword();
            }
        },
    },

    mounted() {
        this.tran = trans;

    }
};
</script>

<style>
</style>
